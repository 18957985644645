.main {
    position: relative;
    width: 100%;
    height: auto;
    background: #F4F4F4;
}

.logo {
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.2em;
    color: rgba(0, 0, 0, 0.15);
    margin-top: 45px;
    margin-bottom: 30px;
}

.heading {
    /* font-family: Montserrat; */
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000000;
    margin-top: 0;
}

.sub_heading {
    /* font-family: Montserrat; */
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.35);
}

.heading_content {
    width: 380px;
    margin: 100px auto 0;
}

.form_container {
    background: #FFFFFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 380px;
    margin: 0 auto 100px;
}

.payment_amount {
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}

form {
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 767px) {
    .form_container {
        width: calc(100% - 60px);
    }
    .heading_content {
        width: 100%;
        margin: 32px auto 0;
    }
}
