body, html {
    height: 100%;
  font-family: 'Montserrat', sans-serif;
}

#root {
    display: flex;
    min-height: 100%;
    min-width: 100%;
}
.modal {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.4);
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}
.modal-container {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0 0 0 0.1);
}
.modal-btn {
    display: block;
    padding: 17px 0;
    width: 100%;
    background: #E10808;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}


.form-container, .main-content {
    margin: 0 20px;
    max-width: 420px;
    flex: 1;
}
.form-container__inner, .main-content__inner {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 10px 30px rgba(0 0 0 0.1);
}
.image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px;
}
.main-content__title {
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #000000;
}
.main-content__txt {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.7);
}


.amount-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.04em;
    color: #000000;
}
.amount-info__title {

}
.amount-info__value {

}

.pay-button {
    display: block;
    padding: 17px 0;
    width: 100%;
    background: #E10808;
    border-radius: 8px;
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.06em;
    color: #FFFFFF;
}
.pay-button:hover {

}
.expire-info {
    position: relative;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px -20px -20px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #E10808;
    border-radius: 0 0 10px 10px;
    background: rgba(225, 8, 8, 0.07);
}
