.main {
    display: flex;
    flex-direction: column;
}
.main-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}
.logo img {
  display: block;
  height: 100%;
}
.mainTitle {
    font-style: normal;
    font-weight: 800;
    font-size: 34px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: #000000;
}
.mainSubTitle {
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.04em;
    color: rgba(0, 0, 0, 0.35);

}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F4F4F4;
  z-index: 100;
}
.loading-light {
  background: rgba(244, 244, 244, 0.7);
}
.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #9f9f9f, 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.5), -1.8em -1.8em 0 0em rgba(159,159,159, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.7), 1.8em -1.8em 0 0em #9f9f9f, 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.5), 1.8em -1.8em 0 0em rgba(159,159,159, 0.7), 2.5em 0em 0 0em #9f9f9f, 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.5), 2.5em 0em 0 0em rgba(159,159,159, 0.7), 1.75em 1.75em 0 0em #9f9f9f, 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.5), 1.75em 1.75em 0 0em rgba(159,159,159, 0.7), 0em 2.5em 0 0em #9f9f9f, -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.5), 0em 2.5em 0 0em rgba(159,159,159, 0.7), -1.8em 1.8em 0 0em #9f9f9f, -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.5), -1.8em 1.8em 0 0em rgba(159,159,159, 0.7), -2.6em 0em 0 0em #9f9f9f, -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.5), -2.6em 0em 0 0em rgba(159,159,159, 0.7), -1.8em -1.8em 0 0em #9f9f9f;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #9f9f9f, 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.5), -1.8em -1.8em 0 0em rgba(159,159,159, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.7), 1.8em -1.8em 0 0em #9f9f9f, 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.5), 1.8em -1.8em 0 0em rgba(159,159,159, 0.7), 2.5em 0em 0 0em #9f9f9f, 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.5), 2.5em 0em 0 0em rgba(159,159,159, 0.7), 1.75em 1.75em 0 0em #9f9f9f, 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.5), 1.75em 1.75em 0 0em rgba(159,159,159, 0.7), 0em 2.5em 0 0em #9f9f9f, -1.8em 1.8em 0 0em rgba(159,159,159, 0.2), -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.5), 0em 2.5em 0 0em rgba(159,159,159, 0.7), -1.8em 1.8em 0 0em #9f9f9f, -2.6em 0em 0 0em rgba(159,159,159, 0.2), -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.5), -1.8em 1.8em 0 0em rgba(159,159,159, 0.7), -2.6em 0em 0 0em #9f9f9f, -1.8em -1.8em 0 0em rgba(159,159,159, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(159,159,159, 0.2), 1.8em -1.8em 0 0em rgba(159,159,159, 0.2), 2.5em 0em 0 0em rgba(159,159,159, 0.2), 1.75em 1.75em 0 0em rgba(159,159,159, 0.2), 0em 2.5em 0 0em rgba(159,159,159, 0.2), -1.8em 1.8em 0 0em rgba(159,159,159, 0.5), -2.6em 0em 0 0em rgba(159,159,159, 0.7), -1.8em -1.8em 0 0em #9f9f9f;
  }
}
