
.expired-payment-wrapper {
}

.expired-payment-wrapper .img-wrap {
  margin: 32px auto;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expired-payment-wrapper h4 {
  font-weight: 800;
  font-size: 25px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #000000;
}

.expired-payment-wrapper h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #595959;
  margin: 0;
}

@media screen and (max-width:767px) {
  .main-payment {
    padding: 20px;
    /* height: auto; */
  }
  .payment-success-wrapper {
    margin: 30px 0;
  }
}
